import axios from 'axios'
import { LinkButton } from '../model/LinkButton'


const backendUrl = process.env.REACT_APP_BACKEND_URL
const DOMAIN = `${backendUrl}/api/links`


export async function addLinkButton(linkButton, pageId) {
    const token = localStorage.getItem('token')
    const body = {
        id: linkButton.uuid,
        position: linkButton.id,
        title: linkButton.title,
        url: linkButton.url,
        thumbnail: "",
    }

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }

    try {
        const response = await axios.post(
            `${DOMAIN}/${pageId}`,
            body,
            {
                headers: headers,
            }
        )
        
        if (response.status === 201) {
            return response.data
        } else if (response.status === 401) {
            console.log("User is not logged in or JWT has expired")
            return false
        }
    } catch (error) {
        console.error(`Error occured while adding new link button: ${error}`)
        return false
    }
}


export async function getLinkButtons(username) {
    const token = localStorage.getItem('token')
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }
    try {
        const response = await axios.get(
            `${DOMAIN}/user/${username}`,
            { headers: headers },

        )
        if (response.status === 200) {
            if (!response.data) return []
            return response.data.map(link => new LinkButton(link.id, link.position, link.title, link.url, ""))
        }
    } catch (error) {
        if (error.response.status === 401) {
            console.error("User is not logged in or JWT has expired")
            console.info("Redirecting to login page")
            window.location.replace("/login")
            return
        }
        console.error(`Error occured while getting new link button: ${error}`)
        return null
    }
}


export async function updateLinkButton(linkButton, pageId) {
    const token = localStorage.getItem('token')
    const data = {
        id: linkButton.uuid,
        position: linkButton.id,
        title: linkButton.title,
        url: linkButton.url,
        thumbnail: linkButton.thumbnail,
    }
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }

    try {
        const response = await axios({
            method: "put",
            url: `${DOMAIN}/${linkButton.uuid}/${pageId}`,
            headers: headers,
            data: data
        })
        return response.status === 204
    } catch (error) {
        console.error(`Error occurred while updating LinkButton: ${error}`)
        return false
    }
}


export async function deleteLinkButton(linkButtonId) {
    const token = localStorage.getItem('token')
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }

    try {
        const response = await axios.delete(
            `${DOMAIN}/${linkButtonId}`,
            { headers: headers },
        )
        return response.status === 204
    } catch (error) {
        console.error(`Error occured while deleting link button: ${error}`)
        return false
    }
}

