import React, { useEffect, useState } from 'react'
import { Box, Button, Collapse, Typography } from '@mui/material'

import { SocialLinkList } from '../links/SocialLinkList'
import { Profile } from './Profile'
import BouncingArrowIcon from './BouncingArrowIcon'

import { getThemeById } from "../../service/ThemeDataService"


export default function ProfileCard({ userData, socials, linkButtons }) {
    const [isProfileExpanded, setProfileExpanded] = useState(false)
    const toggleProfile = () => { setProfileExpanded(!isProfileExpanded) }
    const [theme, setTheme] = useState(null)
    const [textColor, setTextColor] = useState("#f6f6f6")
    const [bioTextColor, setBioTextColor] = useState("#000")

    useEffect(() => {
        const fetchTheme = async () => {
            try {
                if (userData?.videoPages[0]?.themeId) {
                    const response = await getThemeById(userData?.videoPages[0]?.themeId)
                    setTheme(response.data)
                }
            } catch (error) {
                console.error("Error fetching theme:", error)
            }
        }

        fetchTheme()
    }, [userData])

    useEffect(() => {
        if (theme) {
            const updateTextColors = () => {
                if (isLightColor(theme?.buttonColor)) {
                    setTextColor("#040404")
                } else {
                    setTextColor("#f6f6f6")
                }

                if (isLightColor(theme?.bgColor)) {
                    setBioTextColor("#0c0c0c")
                } else {
                    setBioTextColor("#fff")
                }
            }

            updateTextColors()
        }
    }, [theme])


    if (!userData || theme === null) {
        return <Typography>Loading user data...</Typography>
    }

    const isLightColor = (color) => {
        const r = parseInt(color.substring(0, 2), 16) / 255
        const g = parseInt(color.substring(2, 4), 16) / 255
        const b = parseInt(color.substring(4, 6), 16) / 255

        const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b
        return luminance > 0.7
    }

    const fontStyles = {
        REGULAR: 'Catamaran',
        ELEGANT: 'Times New Roman',
        BOLD: 'Bevan',
        MONO: 'monospace',
        PLAYFUL: 'Comic Neue',
    }


    const buttonStyles = {
        FILLED_REGULAR: { backgroundColor: `#${theme.buttonColor}` },
        FILLED_ROUND: { backgroundColor: `#${theme.buttonColor}`, borderRadius: '20px' },
        OUTLINE_REGULAR: {
            backgroundColor: 'transparent',
            border: `3px solid #${theme.buttonColor}`,
            color: "#000",
        },
        OUTLINE_ROUND: {
            backgroundColor: 'transparent',
            border: `3px solid #${theme.buttonColor}`,
            borderRadius: '16px',
            color: "#000",
        },
        SHADOW_REGULAR: {
            backgroundColor: `#${theme.buttonColor}`,
            boxShadow: '3px 3px 2px 3px #000',
        },
        SHADOW_ROUND: {
            backgroundColor: `#${theme.buttonColor}`,
            boxShadow: '3px 3px 2px 3px #000',
            borderRadius: '16px',
        },
    }

    return (
        <Button onClick={toggleProfile}
            sx={{
                width: '100%',
                padding: 0,
                textTransform: 'none',
                color: 'text.second'
            }}>
            <Box sx={{
                position: 'absolute',
                bottom: 0,
                left: '.2rem',
                right: '.2rem',
                paddingBottom: '1rem',
                backgroundColor: `#${theme.bgColor}`,
                borderRadius: '20px',
                maxHeight: '88vh',
            }}>
                <div style={{
                    textAlign: 'center',
                    width: '100vw',
                }}>
                    <Typography
                        variant="body1"
                        component="p"
                        sx={{
                            fontWeight: 400,
                            fontFamily: 'Catamaran',
                            textAlign: 'center',
                            textTransform: 'none',
                            fontSize: '70%',
                            p: .4,
                        }}
                    >
                    </Typography>
                </div>

                {/* profile */}
                <Profile
                    isFullView={isProfileExpanded}
                    userData={userData}
                    color={theme?.buttonColor}
                    bioTxtColor={bioTextColor}
                    fontColor={textColor}
                />

                {/* social links */}
                <SocialLinkList
                    socials={socials}
                    iconColor={theme?.iconColor}
                />

                {!isProfileExpanded && <BouncingArrowIcon />}


                {/* link buttons */}
                <Collapse in={isProfileExpanded}>
                    <div style={{
                        overflowY: 'auto',
                        maxHeight: '50vh',
                    }}>
                        {
                            linkButtons
                                .sort((a, b) => a.position - b.position)
                                .map((btn) => (
                                    <Button
                                        key={btn.id}
                                        href={btn.url.startsWith("http") ? btn.url : `https://${btn.url}`}
                                        sx={{
                                            display: 'block',
                                            color: textColor,
                                            ":hover": {
                                                backgroundColor: '#FF1493',
                                            },
                                            m: '1rem 1.2rem',
                                            p: '.6rem',
                                            ...buttonStyles[theme.buttonStyle]
                                        }}>
                                        <Typography
                                            variant="body1"
                                            component="p"
                                            sx={{
                                                fontWeight: 500,
                                                fontFamily: `${fontStyles[theme.fontStyle]}`,
                                                textAlign: 'center',
                                                textTransform: 'none',
                                            }}
                                        >
                                            {btn.title}
                                        </Typography>
                                    </Button>
                                ))
                        }
                    </div>
                    {/* Vibio signature */}
                    <p style={{
                        padding: '.4rem',
                        margin: '.8rem 0',
                        alignSelf: 'center',
                        color: '#000',
                        fontSize: '1.4rem',
                        fontWeight: 550,
                        fontFamily: 'Catamaran',
                    }}>
                        <a href='https://vibio.co' style={{ textDecoration: 'none', color: bioTextColor }}>
                            Vibio.co
                        </a>
                    </p>
                </Collapse>
            </Box>
        </Button>
    )
}
